import * as faceapi from 'face-api.js';

faceapi.env.monkeyPatch({
    Canvas: HTMLCanvasElement,
    Image: HTMLImageElement,
    ImageData: ImageData,
    Video: HTMLVideoElement,
    createCanvasElement: () => document.createElement('canvas'),
    createImageElement: () => document.createElement('img')
})

const useFaceApi = () => {
    const loadModels = async () => {
        await faceapi.nets.tinyFaceDetector.loadFromUri('/models')
        await faceapi.nets.faceLandmark68Net.loadFromUri('/models')
        await faceapi.nets.faceRecognitionNet.loadFromUri('/models')
        await faceapi.nets.faceExpressionNet.loadFromUri('/models')
    }

    const detectFeatures = async (videoRef, canvasRef) => {
        if (canvasRef && canvasRef.current && videoRef && videoRef.current) {
            canvasRef.current.innerHTML = faceapi.createCanvas(videoRef)
            const displaySize = {
                width: videoRef.current.width,
                height: videoRef.current.height
            }
            faceapi.matchDimensions(canvasRef.current, displaySize)

            const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
            const resizedDetections = faceapi.resizeResults(detections, displaySize)
            faceapi.draw.drawDetections(canvasRef.current, resizedDetections)
            faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections)
            faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections)
            return detections
        }
    }

    return {
        loadModels,
        detectFeatures
    }
}

export default useFaceApi;