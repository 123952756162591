import './App.css';
import PhotoAlbum from "react-photo-album";
import { useCallback, useEffect, useRef } from 'react';
import useFaceApi from './faceapi';

const App = () => {
    const photos = [
        { src: "image1.jpg", width: 800, height: 600 },
        { src: "image2.jpg", width: 1600, height: 900 },
    ]

    const videoRef = useRef(null)
    const canvasRef = useRef(null)
    const { loadModels, detectFeatures } = useFaceApi()

    var brushTimer = undefined;
    const debounceBrushEvent = (func) => {
        if (brushTimer) {
            return
        }
        brushTimer = setTimeout(function () {
            func()
            brushTimer = undefined;
        }, 100)
    }

    useEffect(() => {
        loadModels()
        startVideo()    
      }, []);
    
    const renderPhoto = useCallback(({ imageProps: { alt, style, ...rest } }) => (
        <img
            alt={alt}
            style={{
                ...style,
                borderRadius: "4px",
                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            }}
            {...rest}
        />
    ))

    const startVideo = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ 
            video: {
                // "optional": {
                // facingMode: { exact: "user" }, 
                'sourceId':2
                // }
            } 
        })
        videoRef.current.srcObject = stream;
        videoRef.current.play()
            .then(() => {
                
            }).catch((err) => {
                console.log(err)
            })
    }

    const runFrame = async () => {
        debounceBrushEvent(() => {
            detectFeatures(videoRef, canvasRef)
        })
    }

    const stopVideo = () => {
        videoRef.current.pause() // pause the video
        videoRef.current.srcObject.getTracks()[0].stop() // stop the camera

        detectFeatures(videoRef, canvasRef)
            .then((detections) => {
                if (detections.length == 1) {
                    let expr = detections[0].expressions
                    console.log(expr);
                } else {
                    alert('Please make sure there is only one face in the frame')
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    return (
        <div>
            <button onClick={stopVideo}>Stop</button>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <video 
                    ref={videoRef} 
                    // onPlay={runFrame}
                    width="400" 
                    height="600" 
                    style={{ borderRadius: '10px' }} 
                    autoPlay 
                    playsInline 
                    muted
                />
                <canvas ref={canvasRef} style={{ position: 'absolute' }} />
            </div>
    
            <PhotoAlbum 
                layout="masonry" 
                photos={photos} 
                padding={10} 
                renderPhoto={renderPhoto}
            />
        </div>
    )
}
    
export default App;
    